import React, { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaFacebook,
  FaGithub,
  FaLinkedin,
} from "react-icons/fa";

import Logo from "../assets/logo.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#08111e] text-blue-300">
      <div>
        <img
          src={Logo}
          alt="Logo"
          className="rounded-full"
          style={{ width: "50px", height: "50px" }}
        />
      </div>
      {/* Menu */}
      <div>
        <ul className="md:flex hidden">
          <li>Home</li>
          <li>About Me</li>
          <li>Skills</li>
          <li>Work</li>
          <li>Projects</li>
          <li>Contact</li>
        </ul>
      </div>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!nav ? <FaBars /> : <FaTimes />}
      </div>

      {/* Mobile Menus */}

      <ul
        className={
          !nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#08111e] flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-1xl uppercase font-semibold">Home</li>
        <li className="py-6 text-1xl uppercase font-semibold">About Me</li>
        <li className="py-6 text-1xl uppercase font-semibold">Skills</li>
        <li className="py-6 text-1xl uppercase font-semibold">Work</li>
        <li className="py-6 text-1xl uppercase font-semibold">Projects</li>
        <li className="py-6 text-1xl uppercase font-semibold">Contact</li>
      </ul>

      {/* Social Icons Menus */}
      <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
        <ul>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600 rounded-full rounded-l-none mt-2">
            <a
              href="/"
              className="flex justify-between items-center w-full text-gray-300"
            >
              Facebook <FaFacebook size={35} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333] rounded-full rounded-l-none mt-2">
            <a
              href="/"
              className="flex justify-between items-center w-full text-gray-300"
            >
              Github <FaGithub size={35} />
            </a>
          </li>
          <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600 rounded-full rounded-l-none mt-2">
            <a
              href="/"
              className="flex justify-between items-center w-full text-gray-300"
            >
              Linkedin <FaLinkedin size={35} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
